import './index.css';
import 'react-activity/dist/library.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { lazy, Suspense, useEffect, useState } from 'react';
import ResetPassword from './pages/auth/ResetPassword';
import SignUp from './pages/auth/SignUp';
import Login from './pages/auth/Login';
import { initializeApp, getApps } from 'firebase/app';
import {
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
} from 'firebase/auth';
import Loader from './components/Loader';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

const AppRoutes = lazy(() => import('./pages/AppRoutes'));

const theme = createTheme({
  palette: {
    primary: { main: '#65755d' },
    secondary: { main: '#65755d' },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
});

const firebaseConfig = {
  apiKey: 'AIzaSyCas2uaFxrmtEXJQ0u5xTzdaX12jGyyOGQ',
  authDomain: 'stouch-b68cc.firebaseapp.com',
  projectId: 'stouch-b68cc',
  storageBucket: 'stouch-b68cc.appspot.com',
  messagingSenderId: '495048032543',
  appId: '1:495048032543:web:d0e4fefb1b5a25a863302a',
  measurementId: 'G-DY0FR777HE',
};

const apps = getApps();

if (!apps.length) {
  initializeApp(firebaseConfig);
  const db = getFirestore();
  if (process.env.NODE_ENV === 'development') {
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectAuthEmulator(getAuth(), 'http://localhost:9099');
    connectStorageEmulator(getStorage(), 'localhost', 9199);
    connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
  }
}

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = onAuthStateChanged(auth, user => {
      setUser(user);
      setLoading(false);
    });
    return unsubscribeAuth;
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="bg-white dark:bg-gray-800 min-h-screen dark:text-white">
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <Routes>
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="login" element={<Login />} />
              <Route
                path="*"
                element={
                  user ? <AppRoutes /> : <Navigate replace to="/login" />
                }
              />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </div>
    </ThemeProvider>
  );
}

export default App;
