import { ExitToApp } from '@material-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { ReactComponent as Logo } from '../assets/logo/logo-horizontal.svg';

function OnboardingLayout({ title, children }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center min-h-screen">
      <div className="p-4 flex items-center justify-between w-full">
        <Link to="/">
          <Logo className="text-brand w-40" />
        </Link>
        <button
          onClick={() => {
            const auth = getAuth();
            signOut(auth);
            navigate('/');
          }}
        >
          <ExitToApp className="w-6 h-6" />
        </button>
      </div>
      <div className="flex flex-1 max-w-md">
        <div className="flex flex-col flex-1 justify-center">
          <h1 className="text-4xl font-extrabold mb-12">{title}</h1>
          {children}
        </div>
      </div>
    </div>
  );
}

export default OnboardingLayout;
