import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import ErrorMessage from '../../components/ErrorMessage';
import Button from '../../components/Button';
import { Formik, Form } from 'formik';
import InputGroup from '../../components/fields/InputGroup';
import {
  getAuth,
  sendPasswordResetEmail,
  useDeviceLanguage,
} from 'firebase/auth';

function ResetPassword() {
  const { t } = useTranslation();
  const auth = getAuth();
  useDeviceLanguage(auth);

  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [emailSentTo, setEmailSentTo] = useState();

  const accountSchema = Yup.object().shape({
    email: Yup.string().email().required(t('Required')),
  });

  const onSubmit = values => {
    setLoading(true);
    setError(null);
    const { email } = values;
    sendPasswordResetEmail(auth, email)
      .then(() => setEmailSentTo(email))
      .catch(error => {
        setLoading(false);
        setError(t(error.message));
      });
  };

  return (
    <OnboardingLayout
      title={t(
        emailSentTo
          ? 'Check your inbox.'
          : 'Enter your email to reset your password.',
      )}
    >
      {emailSentTo ? (
        <Fragment>
          <p>{t('passwordResetInstruction', { email: emailSentTo })}</p>
          <Button to="/" className="my-3">
            {t('Go to login page')}
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <ErrorMessage error={error} />
          <Formik
            onSubmit={onSubmit}
            validationSchema={accountSchema}
            initialValues={{ email: '', password: '' }}
          >
            {() => (
              <Form>
                <div className="space-y-4">
                  <InputGroup type="email" name="email" label={t('Email')} />
                  <Button
                    type="submit"
                    className="my-3"
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    {t('Send password reset email')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Fragment>
      )}
    </OnboardingLayout>
  );
}

export default ResetPassword;
