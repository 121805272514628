import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as en from '../i18n/translations/en/common.json';
import * as de from '../i18n/translations/de/common.json';

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};

const browserLanguage = navigator.language || navigator.userLanguage;
const language = browserLanguage.slice(0, 2);
const availableLanguages = Object.keys(resources);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: availableLanguages.includes(language) ? language : 'en',
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: false,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
