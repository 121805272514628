import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import ErrorMessage from '../../components/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import InputGroup from '../../components/fields/InputGroup';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';

function SignUp() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const accountSchema = Yup.object().shape({
    email: Yup.string().email().required(t('Required')),
    password: Yup.string()
      .required(t('No password provided.'))
      .min(8, t('Password is too short - should be 8 chars minimum.')),
  });

  const onSubmit = values => {
    setLoading(true);
    setError(null);
    const auth = getAuth();
    const { email, password } = values;
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => navigate('/'))
      .catch(error => {
        setLoading(false);
        const { code, message } = error;
        if (code === 'auth/weak-password') {
          setError(t('The password is too weak.'));
        } else {
          setError(message);
        }
        console.error(error);
      });
    // After successfully creating a user, the auth listener in App.js triggers and navigates to the onboarding.
  };

  return (
    <OnboardingLayout title={t('Create your account.')}>
      <Formik
        onSubmit={onSubmit}
        validationSchema={accountSchema}
        initialValues={{ email: '', password: '' }}
      >
        {() => (
          <Form>
            <div className="space-y-4">
              <InputGroup type="email" name="email" label={t('Email')} />
              <InputGroup
                type="password"
                name="password"
                label={t('Password')}
              />
              <div className="flex space-x-4 my-3">
                <Button
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  {t('Create account')}
                </Button>
                <Button secondary to="/login">
                  {t('Already have an account?')}
                </Button>
              </div>
              <ErrorMessage error={error} />
            </div>
          </Form>
        )}
      </Formik>
    </OnboardingLayout>
  );
}

export default SignUp;
