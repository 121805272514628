import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import ErrorMessage from '../../components/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import InputGroup from '../../components/fields/InputGroup';
import Button from '../../components/Button';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const accountSchema = Yup.object().shape({
    email: Yup.string().email().required(t('Required')),
    password: Yup.string()
      .required(t('No password provided.'))
      .min(8, t('Password is too short - should be 8 chars minimum.')),
  });

  const onSubmit = values => {
    setLoading(true);
    setError(null);
    const auth = getAuth();
    const { email, password } = values;
    signInWithEmailAndPassword(auth, email, password)
      .then(() => navigate('/'))
      .catch(error => {
        setLoading(false);
        const { code, message } = error;
        if (code === 'auth/wrong-password') {
          setError(t('The entered password is not correct.'));
        } else if (code === 'auth/user-not-found') {
          setError(
            t(
              'There is no user record corresponding to this identifier. The user may have been deleted.',
            ),
          );
        } else {
          setError(message);
        }
        console.error(error);
      });
    // After successfully creating a user, the auth listener in App.js triggers and navigates to the onboarding.
  };

  return (
    <OnboardingLayout title={t('Please log in.')}>
      <ErrorMessage error={error} />
      <Formik
        onSubmit={onSubmit}
        validationSchema={accountSchema}
        initialValues={{ email: '', password: '' }}
      >
        {() => (
          <Form>
            <div className="space-y-4">
              <InputGroup type="email" name="email" label={t('Email')} />
              <InputGroup
                type="password"
                name="password"
                label={t('Password')}
              />
              <div className="flex space-x-4 my-3">
                <Button
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  {t('Log in')}
                </Button>
                <Button to="/reset-password" secondary>
                  {t('Forgot password?')}
                </Button>
                <Button to="/signup" secondary>
                  {t('No account yet?')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </OnboardingLayout>
  );
}

export default Login;
